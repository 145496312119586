import { SetupContext } from "@vue/composition-api"

export const useRoute  =(ctx:SetupContext):SetupContext["root"]["$route"]=>{
    return ctx.root.$route
}
export const useRouter  =(ctx:SetupContext):SetupContext["root"]["$router"]=>{
    return ctx.root.$router
}
export const useRoutePaths =(ctx:SetupContext)=>{
    const route = useRoute(ctx);
    return route.matched.map(item=>({path: item.path,title:item.meta.title}))




}