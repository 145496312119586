


























import { useRoute ,useRoutePaths,useRouter} from "@/common/hooks";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
export default defineComponent({
    name: "SpaceResource",
    setup(props,ctx){
        const paths = ref([])
        onMounted(()=>{
            const route = useRoute(ctx);
            const router = useRouter(ctx);
            paths.value =useRoutePaths(ctx)
            console.log(`当前路由`,{route,router,paths});


        })
        return {paths}
    }
})
